@import "index.scss";
@import "./image-generator/App.scss";
@import "./email-image-generator/App.scss";
@import "./youtube-thumbnail-creator/App.scss";

.main {
  min-height: 100vh;
}
.container {
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-hero {
  display: flex;
  background-color: #dafcdf;
  // height: 526.82px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $size-lg;
  color: $primary-dark;
}

div[data-testid="flowbite-tooltip"] {
  border-radius: 8px;
  padding: 10px;
  transition: 0.2s;
  width: max-content;
  filter: box-shadow(0px 10px 50px rgba(9, 31, 44, 0.15));
}

div[data-testid="flowbite-tooltip"] li {
  color: #68788a;
  width: 100%;
  display: inline-block;
  &:hover {
    color: $primary-dark;
    background-color: transparent;
  }
}

#link div[data-testid="flowbite-tooltip"] {
  width: max-content;
}
#link div[data-testid="flowbite-tooltip-target"] button {
  background-color: #dbeafe;
  color: #1e40af;
}

div[data-testid="flowbite-navbar-collapse"] ul li a {
  color: #68788a;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  width: 100%;
}

#modalBtn button {
  background-color: #fee2e2;
  color: #dc2626;
}

.sub-menu-arrow {
  opacity: 0;
  transition: transform 200ms ease-in, opacity 200ms ease-in,
    -webkit-transform 200ms ease-in;
  margin-top: 3px;
}
div[data-testid="flowbite-navbar-collapse"] ul li {
  display: flex;
  justify-content: space-between;
  align-items: middle;
}
div[data-testid="flowbite-navbar-collapse"] ul li:hover > .sub-menu-arrow {
  opacity: 1;
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

//overriding nav links active state color
li a.md\:text-blue-700 {
  color: $primary-dark !important;
}

li a.md\:hover\:text-blue-700:hover {
  color: $primary-dark !important;
}

.tabs button {
  background: $primary-light !important;
  color: #091f2c96 !important;
  border: 1px solid #091f2c;
  font: normal normal normal 14px/46px;
  height: 29px;
  border-radius: 15px;
  margin-bottom: 68px;
  transition: 0.4s all;
  &:not(button:last-child) {
    margin-right: 11px;
  }
  &:hover {
    background: #e7f7ed 0% 0% no-repeat padding-box !important;
    color: $theme !important;
    border: 1px solid #0bab47;
  }
}
.tabs button[aria-selected="true"] {
  background: #e7f7ed 0% 0% no-repeat padding-box !important;
  color: $theme !important;
  border: 1px solid #0bab47;
}

.tabs div[role="tablist"] {
  justify-content: center;
}

.tab-cards img {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid #e2e5ec;
  transition: 0.4s all;
}
.tab-cards img:hover {
  border: 1px solid $theme;
  box-shadow: 0 10px 15px -3px rgb(4, 67, 28, 0.3),
    0 4px 6px -4px rgba(4, 67, 28, 0.2);
  transform: translateY(-10px);
}
.tab-cards div {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding-top: 4px;
}

.tab-cards div:nth-child(2) {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.tab-badge {
  display: flex;
  justify-content: flex-end;
}

.tab-badge span {
  width: max-content;
}

footer {
  background-color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

footer li {
  margin-top: 0.5rem !important;
  color: $primary-dark !important;
  font-size: 0.9rem !important;
}

footer h2 {
  margin-bottom: 1rem !important;
  color: $primary-dark !important;
  font-weight: 700 !important;
}

.google-btn Button {
  color: #444;
  border-color: $primary-dark;
  background-color: rgb(255 255 255 / 0.1);
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  transition: 0.2s all;
  &:hover {
    border-color: $primary-dark;
    background-color: rgb(149 167 184 / 0.3) !important;
  }
}

@media only screen and (max-width: 640px) {
  .App-hero {
    height: max-content;
  }
}
@media screen and (min-width: 768px) {
  nav div {
    justify-content: start !important;
  }
}

//select
select#category {
  background-color: transparent;
  padding: 0.1rem 0.4rem;
  width: 150px;
  margin: 6px 0px;
  border-radius: 4px;
}

//spinner
span[aria-label="spinner"] svg {
  fill: $theme !important;
}

//typewriter
.editor_handlebar {
  height: 16px;
  background-color: #57606a;
  border-radius: 10px 10px 0 0;
}
.editor_handlebar_buttons {
  padding-top: 5px;
  padding-left: 11px;
  display: flex;
}
.editor_handlebar_buttons:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ff605c;
  margin-right: 4px;
}
.editor_handlebar_buttons:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ffbd44;
  margin-right: 4px;
}
.editor_content {
  padding: 4rem;
  padding-bottom: 6rem;
  background: rgba($primary-dark, 0.9);
  border-radius: 0 0 10px 10px;
  color: $primary-light;
  height: 265px;
}

.code {
  color: $tag-color;
  font-size: 1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
  background-color: transparent !important;
}

.code span:nth-child(1)::before {
  content: "<p ";
}

.code span:nth-child(2)::after {
  color: $tag-color;
  content: "> ";
}

.code span:last-child::after {
  color: $tag-color;
  content: "\a </p>";
  white-space: pre;
}
.typewriter code {
  color: $primary-light;
  border-right: 0.15em solid $cursor;
  animation: blink-caret 0.75s step-end infinite;
}

.box_fade {
  border-radius: 25%;
  background-color: $primary-light;
  border: none;
  box-shadow: inset 0px 0px 60px 40px rgba($primary-light, 0.9);
}
.hero_wrapper {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23b5b5b5'%3e%3cpath stroke-opacity='0.15' d='M0 .5H31.5V32'/%3e%3c/svg%3e") !important;
}

.octagon {
  height: 504px;
  background-image: url("data:image/svg+xml, %3Csvg width='504' height='504' viewBox='0 0 604 604' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M407.679 0H196.321C183.9 0 171.969 4.93581 163.176 13.7288L13.7288 163.176C4.93581 171.969 0 183.9 0 196.321V407.679C0 420.1 4.93581 432.031 13.7288 440.824L163.176 590.271C171.969 599.064 183.867 604 196.321 604H407.679C420.1 604 432.031 599.064 440.824 590.271L590.271 440.824C599.064 432.031 604 420.133 604 407.679V196.321C604 183.9 599.064 171.969 590.271 163.176L440.791 13.7288C432.031 4.93581 420.1 0 407.679 0Z'  fill='%2395A7B8' fill-opacity='0.1' /%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  background-position: center;
}

.blur_box {
  background-color: rgba($primary-light, 0.2);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
}
.gallary_content {
  background: linear-gradient(0deg, #092231 0%, rgba(226, 229, 236, 0) 90.2%);
  background-blend-mode: multiply;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  backdrop-filter: blur(2px);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $cursor;
  }
}

@media only screen and (max-width: 1024px) {
  .editor_content {
    padding: 2rem;
  }
  .octagon {
    background-image: none;
  }
}

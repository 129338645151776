.youtube #gen-img-preview.DisplayImgPreview .img-thumbnail {
  max-width: 650px;
  width: 100%;
}
.youtube #gen-img-preview.DisplayImgPreview {
  width: 650px;
  margin: 25px auto 0;
  float: inherit;
  height: 360px;
}
.youtube .image-details {
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.youtube .image-settings {
  border-bottom: 0.5px solid #e2e5ec;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.youtube .button-wrapper.button-wrapper-preview {
  width: 650px;
  margin: 25px auto 0;
}

.youtube .DisplayImg > h2 {
  font-size: 90px;
  line-height: 100px;
  bottom: 50px;
}

.youtube .DisplayImg > h2 {
  top: initial;
  bottom: 50px;
  left: 60px;
  max-width: 80%;
}

.youtube #gen-img-preview > h2 {
  max-width: 90%;
  bottom: 25px;
  left: 30px;
  top: initial;
  font-size: 46px;
  line-height: 51px;
}

.youtube .DisplayImg > h2 span {
  color: white;
  background: #0bab47;
  box-shadow: 0 0 0 10px transparent;
  padding: 0;
  line-height: 1.4;
}

.youtube .thumbnail-screenshot-wrapper #gen-img-preview.DisplayImgPreview {
  width: 215px;
  height: 121px;
  margin: 0px;
}

.youtube .thumbnail-screenshot-wrapper #gen-img-preview > h2 {
  font-size: 12px;
  line-height: 14px;
  bottom: 6px;
  left: 8px;
  max-width: 70%;
}

.youtube .img-thumbnail {
  height: 720px;
}

.youtube .DisplayImg .customRed {
  background-color: #ff5500;
}
.youtube .DisplayImg .dark {
  background-color: #091f2c;
}
.youtube .DisplayImg .gogreen {
  background-color: #0bab47;
}
.youtube .DisplayImg .checkboxBlue {
  background-color: #1f6dca;
}
.youtube .DisplayImg .checkboxGrey {
  background-color: #e2e5ec;
}

#dark[type="radio"] {
  background-color: #091f2c;
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27091f2c%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
  }
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

#grey[type="radio"] {
  background-color: #e2e5ec;
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27091f2c%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
  }
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

.youtube .DisplayImg .checkboxGrey h2 {
  color: #091f2c;
}

.youtube #gen-img {
  width: 1280px;
  height: 720px;
}

@media (max-width: 991px) {
  .screenshot {
    display: none;
  }
}

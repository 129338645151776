@import "../index.scss";

.sr-only {
  white-space: normal !important;
}

.DisplayImg > h2.extra-small {
  font-size: 46px;
}
.DisplayImg > h2.small {
  font-size: 54px;
}
.DisplayImg > h2.medium {
  font-size: 66px;
}
.DisplayImg > h2.large {
  font-size: 74px;
}
.DisplayImg > h2.oversized {
  font-size: 88px;
}

#gen-img-preview > h2.extra-small {
  font-size: 1.25rem;
}
#gen-img-preview > h2.small {
  font-size: 1.5rem;
}
#gen-img-preview > h2.medium {
  font-size: 1.875rem;
}
#gen-img-preview > h2.large {
  font-size: 2.25rem;
}
#gen-img-preview > h2.oversized {
  font-size: 3rem;
}

.custom-file-input::-webkit-file-upload-button {
  display: none;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

input[type="text"]::placeholder {
  font-size: 0.875rem;
  font-style: italic;
}

button.btn {
  border: 1px solid #091f2c;
  color: #091f2c;
  font-family: "Roboto";
  font-size: 1rem;
  text-align: center;
  padding: 10px 20px;
  margin-right: 20px;
}
button.btn-primary {
  border: none;
  background: #0bab47;
  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;
}
button.btn-primary:hover {
  background-color: #00893e;
}

.button-wrapper {
  margin-bottom: 25px;
  margin-left: 0px;
}

.image-details {
  border-bottom: 0.5px solid #e2e5ec;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.gomarkets-logo {
  position: absolute;
  top: 45px;
  left: 40px;
  z-index: 2;
}
#gen-img-preview.overlay2 .gomarkets-logo {
  max-width: 160px;
}
.overlay2 .gomarkets-logo {
  top: auto;
  left: auto;
  bottom: 30px;
  right: 50px;
}
.gomarkets-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.overlay1 {
  width: 1200px;
  height: 628px;
}
.overlay2 {
  width: 1080px;
  height: 1080px;
}
.overlay3 {
  width: 1080px;
  height: 1920px;
}

.bg-info {
  background-color: rgb(255, 255, 255) !important;
}

.img-thumbnail {
  padding: 0;
  border-radius: 0;
  position: relative;
  float: right;
  height: 628px;
  width: auto;
}

#gen-img.overlay2 .img-thumbnail {
  height: 780px;
}

#gen-img-preview.overlay2 .img-thumbnail {
  top: 0;
  height: auto;
}

.DisplayImg {
  position: relative;
  margin: auto;
  float: left;
  overflow: hidden;
}

#gen-img-preview.DisplayImgPreview {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 360px;
  // background: linear-gradient(
  //   90deg,
  //   rgba(9, 31, 44, 0.45) 60.43%,
  //   rgba(9, 31, 44, 0) 100%
  // );
}

#gen-img-preview.DisplayImgPreview .img-thumbnail {
  height: auto;
  top: 0%;
  max-width: 51%;
}

.overlay2#gen-img-preview.DisplayImgPreview .img-thumbnail {
  top: 0;
  max-width: 75%;
}

.overlay2#gen-img-preview.DisplayImgPreview {
  height: 690px;
}

.imgText {
  position: relative;
  width: 100%;
  margin: auto;
}

.DisplayImg > h2 {
  position: absolute;
  top: 165px;
  left: 80px;
  transform: none;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 2.25rem;
  color: white;
  font-family: goma-sans-bold;
  font-weight: 800;
  line-height: 1.25;
  max-width: 460px;
  z-index: 2;
}

.overlay2.DisplayImg > h2 {
  left: 80px;
  bottom: 250px;
  top: auto;
  max-width: 590px;
}

#gen-img-preview.overlay2.DisplayImg > h2 {
  max-width: 250px;
  bottom: 200px;
  top: auto;
}

#gen-img-preview.overlay1 .gomarkets-logo {
  top: 25px;
  left: 20px;
}
#gen-img-preview > h2 {
  top: 80px;
  left: 40px;
  max-width: 290px;
}
#gen-img-preview > p {
  font-size: 0.875rem;
  bottom: 40px;
  left: 40px;
}

.DisplayImg > p {
  position: absolute;
  bottom: 50px;
  left: 80px;
  transform: none;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color: white;
  font-family: Roboto;
  z-index: 2;
}

.overlay2.DisplayImg > p {
  left: 80px;
  bottom: 80px;
}
.colorPicker div[data-testid="flowbite-tooltip-target"] button {
  border: 1px solid #cfd1d5;
  border-radius: 0;
  background-color: #ffffff;
  color: rgba(40, 42, 60, 0.6);
  font-style: italic;
  width: 180px;
  height: 40px;
}
.colorPicker div[data-testid="flowbite-tooltip-target"] button span {
  width: 100%;
  justify-content: space-between;
}
.colorPicker div[data-testid="flowbite-tooltip"] {
  width: 180px;
  height: 40px;
  border: 1px solid #cfd1d5;
  border-radius: 0;
  padding: 0;
  padding-left: 12px;
}
.colorPicker input[type="radio"] {
  cursor: pointer;
  border-radius: 0;
  width: 18.5px;
  height: 18.5px;
  &:checked {
    border: 1px solid #95a7b8;
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
  }
}
#white[type="radio"] {
  background-color: white;
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27091f2c%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
  }
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}
#green[type="radio"] {
  background-color: #0bab47;
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}
#orange[type="radio"] {
  background-color: #ff5500;
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}
#blue[type="radio"] {
  background-color: #1f6dca;
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}
#red[type="radio"] {
  background-color: #e52431;
  &:focus {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

.webinar .preview-wrapper {
  background-color: rgba(226, 229, 236, 0.09);
  border: 1px solid #ecf0fc;
  display: inline-block;
  width: 100%;
  padding: 80px 80px 20px;
}

.webinar #gen-img-preview.DisplayImgPreview {
  height: 406px;
  width: 777px;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay2 {
  height: 700px;
  max-width: 700px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay1 h2 {
  font-size: 36px;
  line-height: 44px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay1 .col-span-2 p {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay1 .col-span-1 p {
  font-size: 20px;
  line-height: 24px;
}

.webinar
  #gen-img-preview.DisplayImgPreview.overlay1
  .col-span-1
  p:nth-of-type(2) {
  font-size: 15px;
  line-height: 18px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay2 .col-span-1 p {
  font-size: 23px;
  line-height: 28px;
}

.webinar
  #gen-img-preview.DisplayImgPreview.overlay2
  .col-span-1
  p:nth-of-type(2) {
  font-size: 18px;
  line-height: 22px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay2 .col-span-2 p {
  font-size: 23px;
  line-height: 28px;
  font-weight: 700;
  text-align: left;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay2 .col-span-2 h2 {
  font-size: 48px;
  line-height: 58px;
}

.webinar #gen-img.DisplayImg.overlay2 .col-span-2 h2 {
  font-size: 76px;
  line-height: 92px;
  font-weight: 600;
}

//overlay3 preview
.webinar #gen-img-preview.DisplayImgPreview.overlay3 {
  height: 1243.14px;
  width: 699.27px;
  background-color: $primary-dark;
  color: $primary-light !important;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay3 #top {
  height: 692.4px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay3 #top .text-area {
  width: 100%;
  height: 149.57px;
  background-color: #ecf0fc;
  position: absolute;
  bottom: 0;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay3 #top .img-area {
  width: 258.99px;
  height: 258.99px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay3 #bottom {
  height: 550.74px;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay3 #bottom .text-area {
  width: 440.28px;
  height: 103.6px;
  background-color: $theme;
  position: absolute;
  top: 0;
  right: 0;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay3 #bottom .img-area {
  width: 258.99px;
  height: 258.99px;
  position: absolute;
  top: 0;
  left: 0;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay3 h2 {
  font-size: 49px;
  line-height: 58.8px;
  font-weight: 600;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay3 #top p {
  max-width: 350.93px;
  margin-left: 53px;
  font-weight: 700;
  color: #282a3c;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay3 #bottom p {
  line-height: 23px;
  max-width: 380.71px;
  margin-left: 29.78px;
  font-weight: 700;
  font-size: 23px;
  color: $primary-light;
}

//overlay3 main
.webinar #gen-img.DisplayImg.overlay3 {
  background-color: $primary-dark;
  color: $primary-light !important;
}

.webinar #gen-img.DisplayImg.overlay3 #top {
  height: 1069px;
}

.webinar #gen-img.DisplayImg.overlay3 #top .text-area {
  width: 100%;
  height: 231px;
  background-color: #ecf0fc;
  position: absolute;
  bottom: 0;
}

.webinar #gen-img.DisplayImg.overlay3 #top .img-area {
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.webinar #gen-img.DisplayImg.overlay3 #bottom {
  height: 851px;
}
.webinar #gen-img.DisplayImg.overlay3 #bottom .text-area {
  width: 680px;
  height: 160px;
  background-color: $theme;
  position: absolute;
  top: 0;
  right: 0;
}

.webinar #gen-img.DisplayImg.overlay3 #bottom .img-area {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
}
.webinar #gen-img.DisplayImg.overlay3 h2 {
  font-size: 76px;
  line-height: 91.2px;
  font-weight: 600;
}
.webinar #gen-img.DisplayImg.overlay3 #top p {
  max-width: 542px;
  margin-left: 82px;
  font-weight: 700;
  color: #282a3c;
}
.webinar #gen-img.DisplayImg.overlay3 #bottom p {
  line-height: 43.57px;
  max-width: 558px;
  margin-left: 46px;
  font-size: 36px;
  font-weight: 700;
  color: $primary-light;
}

//overlay4 preview
.webinar #gen-img-preview.DisplayImgPreview.overlay4 {
  // height: 582.72px;
  // width: 841.71px;
  height: 482.72px;
  width: 741.71px;
  background-color: $primary-dark;
  color: $primary-light !important;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #left {
  /* width: 582.72px;
  height: 479.13px; */
  width: 532.72px;
  height: 379.13px;
  float: left;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #right {
  /*  width: 258.99px;
  height: 479.13px; */
  width: 208.99px;
  height: 379.13px;
  float: right;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #bottom {
  width: 100%;
  height: 103.6px;
  background-color: $theme;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #right .text-area {
  width: 208.99px;
  height: 170.14px;
  background-color: #ecf0fc;
  color: #282a3c;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay4 #right .img-area {
  width: 100%;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 h2 {
  font-size: 44px;
  line-height: 48.8px;
  font-weight: 600;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #right p {
  max-width: 211.08px;
  margin-left: 22.66px;
  font-weight: 700;
  color: #282a3c;
}
.webinar #gen-img-preview.DisplayImgPreview.overlay4 #bottom p {
  line-height: 27.84px;
  max-width: 380.71px;
  margin-left: 45.97px;
  font-weight: 700;
  font-size: 23px;
  color: $primary-light;
}
//overlay4 main
.webinar #gen-img.DisplayImg.overlay4 {
  height: 900px;
  width: 1300px;
  background-color: $primary-dark;
  color: $primary-light !important;
}
.webinar #gen-img.DisplayImg.overlay4 #left {
  /* width: 582.72px;
  height: 479.13px; */
  width: 900px;
  height: 740px;
  float: left;
}
.webinar #gen-img.DisplayImg.overlay4 #right {
  /*  width: 258.99px;
  height: 479.13px; */
  width: 400px;
  height: 740px;
  float: right;
}
.webinar #gen-img.DisplayImg.overlay4 #bottom {
  width: 100%;
  height: 160px;
  background-color: $theme;
}
.webinar #gen-img.DisplayImg.overlay4 #right .text-area {
  width: 400px;
  height: 340px;
  background-color: #ecf0fc;
  color: #282a3c;
}

.webinar #gen-img.DisplayImg.overlay4 #right .img-area {
  width: 400px;
  height: 400px;
}
.webinar #gen-img.DisplayImg.overlay4 h2 {
  font-size: 76px;
  line-height: 91.2px;
  font-weight: 600;
}
.webinar #gen-img.DisplayImg.overlay4 #right p {
  max-width: 326px;
  margin-left: 35px;
  font-weight: 700;
  color: #282a3c;
}
.webinar #gen-img.DisplayImg.overlay4 #bottom p {
  line-height: 43.57px;
  max-width: 588px;
  margin-left: 71px;
  font-weight: 700;
  font-size: 36px;
  color: $primary-light;
}
//overlay4 main end

.webinar .DisplayImg .img-thumbnail {
  max-width: 100%;
  top: 0%;
  height: auto;
}

.webinar #gen-img-preview.DisplayImgPreview .img-thumbnail {
  max-width: 100%;
  top: 0%;
  //top: -50%;
  height: auto;
}

.webinar #gen-img-preview.overlay1 .gomarkets-logo {
  top: 23px;
  left: auto;
  right: 21px;
}

.webinar .DisplayImg.overlay1 .gomarkets-logo {
  top: 48px;
  left: auto;
  right: 43px;
}

.webinar #gen-img-preview.DisplayImgPreview > h6 {
  top: 24px;
  left: 32px;
  font-size: 15px;
  line-height: 18px;
}

.webinar .DisplayImg > h6 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 60px;
  font-family: "Roboto";
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
}

.webinar .DisplayImg .customRed {
  background-color: #ff5500;
}
.webinar .DisplayImg .white {
  background-color: #ffffff;
}
.webinar .DisplayImg .gogreen {
  background-color: #0bab47;
}
.webinar .DisplayImg .checkboxBlue {
  background-color: #1f6dca;
}
.webinar .DisplayImg .checkboxRed {
  background-color: #e52431;
}

.webinar #gen-img-preview > h2 {
  top: 55px;
  left: 32px;
  max-width: 55%;
  z-index: 3;
}

.webinar #gen-img-preview > p {
  bottom: 20px;
  left: 32px;
  z-index: 3;
}

.webinar .presenter-wrapper {
  position: absolute;
  bottom: 20px;
  right: 25px;
  max-width: 220px;
}

.webinar #gen-img-preview .secondary-image {
  max-width: 135px;
}

.webinar .secondary-image-wrapper {
  border-radius: 50%;
  position: absolute;
  bottom: 95px;
  right: 70px;
}

.webinar #gen-img-preview.DisplayImgPreview.overlay2 .img-thumbnail {
  top: 0;
  width: 700px;
  max-width: 700px;
}

.webinar .DisplayImg.overlay2 > h6 {
  left: 0;
  right: 0;
  top: 80px;
  text-align: center;
  z-index: 3;
}

.webinar #gen-img-preview.overlay2.DisplayImg > h2 {
  bottom: 0;
  top: 70px;
  max-width: 80%;
  left: 0;
  right: 0;
  text-align: center;
  margin-left: 10%;
}

.webinar #gen-img-preview.overlay2 .gomarkets-logo {
  max-width: 220px;
  width: 100%;
  bottom: 20px;
  right: 32px;
}

.webinar .overlay2 .secondary-image-wrapper {
  bottom: 300px;
  right: 0;
  left: 0;
  width: 100%;
}

.webinar #gen-img-preview.overlay2 .secondary-image {
  margin: 0 auto;
  max-width: 200px;
}

.webinar .DisplayImg .secondary-image {
  margin: 0 auto;
  max-width: 275px;
  height: auto;
  display: block;
  border-radius: 50%;
}

.webinar .overlay2 .presenter-wrapper {
  bottom: 220px;
  left: 0px;
  right: 0px;
  max-width: 100%;
}

.webinar .overlay2 .presenter-wrapper p {
  max-width: 300px;
  margin: 0 auto;
}

.webinar .img-thumbnail-overlay {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.25;
  z-index: 2;
}

.webinar #gen-img.DisplayImg > h2 {
  left: 10%;
  top: 130px;
  max-width: 80%;
  bottom: auto;
  text-align: center;
}

.webinar #gen-img.DisplayImg > p {
  left: 65px;
  bottom: 40px;
  font-size: 32px;
  line-height: 38px;
}

.webinar #gen-img.DisplayImg.overlay2 > p {
  left: 30px;
  bottom: 30px;
}

.webinar #gen-img.DisplayImg .presenter-wrapper {
  right: 60px;
  bottom: 40px;
  max-width: 440px;
}

.webinar #gen-img.DisplayImg .presenter-wrapper h5 {
  font-size: 40px;
  line-height: 47px;
}

.webinar #gen-img.DisplayImg .presenter-wrapper p {
  font-size: 28px;
  line-height: 33px;
}

.webinar #gen-img.DisplayImg .secondary-image-wrapper {
  right: 145px;
  bottom: 165px;
}

.webinar #gen-img.DisplayImg.overlay2 .secondary-image-wrapper {
  bottom: 370px;
}

.webinar #gen-img.overlay2 .img-thumbnail {
  height: 1080px;
}

.webinar #gen-img.overlay2 .gomarkets-logo {
  bottom: 60px;
  right: 55px;
}

.webinar #gen-img.DisplayImg.overlay2 .presenter-wrapper {
  max-width: 100%;
  right: 0;
  bottom: 200px;
}

.webinar #gen-img.DisplayImg.overlay1 h2 {
  font-size: 59px;
  line-height: 72px;
}

.webinar #gen-img-preview h2.large {
  font-size: 30px;
  line-height: 36px;
  font-family: "goma-sans-semi";
}
.webinar #gen-img h2.large {
  font-size: 80px;
  line-height: 96px;
  font-family: "goma-sans-semi";
}

@media (max-width: 800px) {
  .DisplayImg > h2 {
    font-size: 1em;
  }
  .DisplayImg > .topText {
    top: 45% !important;
  }
}

/*************************Global*************************/
#gen-img-preview.global .bottom_text p {
  font-size: 1.125rem !important;
  color: white !important;
  font-weight: 400;
}

#gen-img-preview.global .bottom_text {
  bottom: 16.97px;
  left: 40px;
  position: absolute;
  transform: none;
  text-align: left;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.DisplayImg.global .bottom_text {
  bottom: 27px;
  left: 80px;
  position: absolute;
  transform: none;
  text-align: left;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.DisplayImg.global .bottom_text p {
  font-size: 1.75rem !important;
  color: white !important;
  font-weight: 400;
}

#gen-img-preview.global .top_text h2 {
  font-size: 2.25rem;
  color: white;
  font-weight: 800;
  line-height: 1.25;
}

.DisplayImg.global .top_text h2 {
  font-size: 74px;
  color: white;
  font-weight: 800;
  line-height: 1.25;
}

.DisplayImg.global .top_text {
  position: absolute;
  top: 66px;
  left: 80px;
  transform: none;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.DisplayImg.overlay1.global .top_text {
  top: 142px;
}

#gen-img-preview.overlay1.DisplayImg.global .top_text {
  top: 89.22px;
  left: 40px;
}

.DisplayImg.overlay2.global .top_text {
  top: 142px;
}
#gen-img-preview.overlay2.DisplayImg.global .top_text {
  top: 66px;
  left: 40px;
}

#gen-img-preview.global .cta-label {
  min-width: 165.88px;
}
#gen-img-preview.global .cta-label span {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

#gen-img.global .cta-label {
  min-width: 264px;
}
#gen-img.global .cta-label div {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  min-width: 210px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#gen-img-preview.DisplayImg.global .top_text h2.extra-small {
  font-size: 1.75rem;
}

#gen-img-preview.DisplayImg.global .top_text h2.small {
  font-size: 2.125rem;
}

#gen-img-preview.DisplayImg.global .top_text h2.medium {
  font-size: 2.625rem;
}

#gen-img-preview.DisplayImg.global .top_text h2.large {
  font-size: 2.875rem;
}
#gen-img-preview.DisplayImg.global .top_text h2.oversized {
  font-size: 3.25rem;
}

#gen-img.DisplayImg.global .top_text h2.extra-small {
  font-size: 2.875rem;
}
#gen-img.DisplayImg.global .top_text h2.small {
  font-size: 3.375rem;
}
#gen-img.DisplayImg.global .top_text h2.medium {
  font-size: 4.125rem;
}
#gen-img.DisplayImg.global .top_text h2.large {
  font-size: 4.625rem;
}
#gen-img.DisplayImg.global .top_text h2.oversized {
  font-size: 5.125rem;
}

/*************************Branding*************************/
.branding#gen-img-preview.DisplayImgPreview.overlay1 {
  height: 399px;
  width: 100%;
  max-width: 764px !important;
}

.branding#gen-img-preview.DisplayImgPreview.overlay2 {
  height: 700px;
  width: 100%;
  max-width: 700px;
}

.branding#gen-img-preview.DisplayImgPreview.overlay1 #bottom,
.branding#gen-img-preview.DisplayImgPreview.overlay2 #bottom {
  width: 100%;
  height: 64.6px;
  background-color: $primary-dark;
}

.branding#gen-img-preview.DisplayImgPreview.overlay1 .img-thumbnail {
  max-width: 100%;
  width: 100%;
  height: 335px !important;
}

.branding#gen-img-preview.DisplayImgPreview.overlay2 .img-thumbnail {
  max-width: 100%;
  width: 100%;
  height: 635.4px !important;
}

//generated
.branding#gen-img.DisplayImg.overlay1 {
  height: 628px;
  max-width: 1200px;
}

.branding#gen-img.DisplayImg.overlay2 {
  height: 1080px;
  max-width: 1080px;
}

.branding#gen-img.DisplayImg.overlay1 #bottom,
.branding#gen-img.DisplayImg.overlay2 #bottom {
  width: 100%;
  height: 170px;
  background-color: $primary-dark;
}

.branding#gen-img.DisplayImg.overlay1 .img-thumbnail {
  max-width: 100%;
  width: 100%;
  height: 458px !important;
}

.branding#gen-img.DisplayImg.overlay2 .img-thumbnail {
  max-width: 100%;
  width: 100%;
  height: 910px !important;
}

/*************************Market-Movers*************************/
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 {
  height: 700px;
  max-width: 700px;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 {
  height: 1242px;
  max-width: 1080px;
}

.marketMovers .preview-wrapper {
  background-color: rgba(226, 229, 236, 0.09);
  border: 1px solid #ecf0fc;
  display: inline-block;
  width: 100%;
  padding: 80px 80px 20px;
}

.marketMovers #gen-img-preview.DisplayImgPreview {
  height: 700px;
  width: 700px;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #top {
  width: 100%;
  height: 103.6px;
  background-color: $primary-dark;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #top {
  width: 100%;
  height: 300px;
  background-color: $primary-dark;
  justify-content: center;
  align-items: end;
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #top p,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #top p {
  line-height: 39.45px;
  max-width: 380.71px;
  margin-left: 35.95px;
  font-weight: 600;
  font-size: 32.36px;
  color: $primary-light;

  &:is(#gen-img-preview.DisplayImgPreview.overlay2 #top p) {
    margin-left: 0;
    margin-bottom: 30px;
    line-height: 97.34px;
    font-size: 59.85px;
    max-width: 100%;
  }
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #bottom {
  width: 100%;
  height: 103.6px;
  background-color: $primary-dark;
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #bottom {
  width: 100%;
  height: 300px;
  background-color: $primary-dark;
  justify-content: center;
  align-items: start;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #bottom img {
  margin: 0;
  margin-top: 50px;
  width: 413px;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left {
  float: left;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left .block1,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left .block1 {
  background-color: #ecf4fa;
  width: 355.97px;
  height: 366.98px;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left .block1 h2,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left .block1 h2 {
  @include font("goma-sans-semi", 58px, 600, 67.22px, normal);
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left .block1 h3,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left .block1 h3 {
  @include font("goma-sans-semi", 32px, 600, 39.01px, normal);
  text-transform: uppercase;
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left .block2,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left .block2 {
  background-color: $primary-light;
  width: 355.97px;
  height: 126.5px;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #left .block2 h2,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #left .block2 h2 {
  @include font("inter", 44px, 600, 53.25px, normal);
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #right,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #right {
  float: right;
}

.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #right .text-area,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #right .text-area {
  width: 342.03px;
  height: 152.1px;
  @include font("inter", 32px, 600, 38.73px, normal);
  color: $primary-light;
}
.marketMovers #gen-img-preview.DisplayImgPreview.overlay1 #right .img-area,
.marketMovers #gen-img-preview.DisplayImgPreview.overlay2 #right .img-area {
  width: 342.03px;
  background-color: #878c95;
}

//main
.marketMovers #gen-img.DisplayImg.overlay1 {
  height: 1080px;
  max-width: 1080px;
}
.marketMovers #gen-img.DisplayImg.overlay2 {
  height: 1920px;
  max-width: 1080px;
}

.marketMovers #gen-img.DisplayImg {
  height: 1080px;
  max-width: 1080px;
}

.marketMovers #gen-img.DisplayImg.overlay1 #top {
  width: 100%;
  height: 158px;
  background-color: $primary-dark;
}
.marketMovers #gen-img.DisplayImg.overlay2 #top {
  width: 100%;
  height: 577px;
  background-color: $primary-dark;
  justify-content: center;
  align-items: end;
}
.marketMovers #gen-img.DisplayImg.overlay1 #top p,
.marketMovers #gen-img.DisplayImg.overlay2 #top p {
  line-height: 60.95px;
  max-width: 438px;
  margin-left: 56px;
  font-weight: 600;
  font-size: 50px;
  color: $primary-light;
  &:is(#gen-img.DisplayImg.overlay2 #top p) {
    margin-left: 0;
    margin-bottom: 80px;
    line-height: 97.34px;
    font-size: 79.85px;
    max-width: 100%;
  }
}
.marketMovers #gen-img.DisplayImg.overlay1 #bottom {
  width: 100%;
  height: 158px;
  background-color: $primary-dark;
}
.marketMovers #gen-img.DisplayImg.overlay2 #bottom {
  width: 100%;
  height: 581px;
  background-color: $primary-dark;
  justify-content: center;
  align-items: start;
}
.marketMovers #gen-img.DisplayImg.overlay2 #bottom img {
  margin: 0;
  margin-top: 100px;
  width: 613.5px;
}

.marketMovers #gen-img.DisplayImg.overlay1 #left,
.marketMovers #gen-img.DisplayImg.overlay2 #left {
  float: left;
}

.marketMovers #gen-img.DisplayImg.overlay1 #left .block1,
.marketMovers #gen-img.DisplayImg.overlay2 #left .block1 {
  background-color: #ecf4fa;
  width: 550px;
  height: 568px;
}
.marketMovers #gen-img.DisplayImg.overlay1 #left .block1 h2,
.marketMovers #gen-img.DisplayImg.overlay2 #left .block1 h2 {
  @include font("goma-sans-semi", 90px, 600, 104.31px, normal);
}
.marketMovers #gen-img.DisplayImg.overlay1 #left .block1 h3,
.marketMovers #gen-img.DisplayImg.overlay2 #left .block1 h3 {
  @include font("goma-sans-semi", 50px, 600, 60.95px, normal);
  text-transform: uppercase;
}
.marketMovers #gen-img.DisplayImg.overlay1 #left .block2,
.marketMovers #gen-img.DisplayImg.overlay2 #left .block2 {
  background-color: $primary-light;
  width: 550px;
  height: 196px;
}
.marketMovers #gen-img.DisplayImg.overlay1 #left .block2 h2,
.marketMovers #gen-img.DisplayImg.overlay2 #left .block2 h2 {
  @include font("inter", 70px, 600, 84.72px, normal);
}
.marketMovers #gen-img.DisplayImg.overlay1 #right,
.marketMovers #gen-img.DisplayImg.overlay2 #right {
  float: right;
}

.marketMovers #gen-img.DisplayImg.overlay1 #right .text-area,
.marketMovers #gen-img.DisplayImg.overlay2 #right .text-area {
  width: 528px;
  height: 236px;
  @include font("inter", 50px, 600, 60.51px, normal);
  color: $primary-light;
}
.marketMovers #gen-img.DisplayImg.overlay1 #right .img-area,
.marketMovers #gen-img.DisplayImg.overlay2 #right .img-area {
  width: 528px;
}

/*************************ECO-Calendar*************************/
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 {
  height: 700px;
  max-width: 700px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 {
  height: 1242px;
  max-width: 1080px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview {
  height: 700px;
  width: 700px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview #top .top-badge {
  border-radius: 60px;
  padding: 10px 20px 10px 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20.05px;
  text-transform: uppercase;

  span {
    font-weight: 500;
    line-height: normal;
  }
}
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 #top {
  width: 100%;
  height: 123.6px;
  background-color: $primary-dark;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 {
  & .badge-block {
    justify-content: end;
  }
  & .text-block {
    justify-content: start;
  }
}

.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 {
  & .badge-block,
  .text-block {
    justify-content: center !important;
  }
}
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 #top {
  width: 100%;
  height: 300px;
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 #top p,
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 #top p {
  line-height: 39.45px;
  max-width: 380.71px;
  margin-left: 22.95px;
  font-weight: 700;
  font-size: 32.36px;
  color: $primary-light;

  &:is(#gen-img-preview.DisplayImgPreview.overlay2 #top p) {
    margin-left: 0;
    margin-bottom: 30px;
    line-height: 97.34px;
    font-size: 59.85px;
    max-width: 100%;
  }
}
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 #bottom {
  width: 100%;
  height: 103.6px;
  background-color: $primary-dark;
  justify-content: space-between;
}
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 #bottom {
  width: 100%;
  height: 300px;
  background-color: $primary-dark;
  justify-content: center;
  flex-direction: column;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 #bottom img {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 413px;
}
.ecoCalendar #gen-img-preview.DisplayImgPreview #bottom p {
  font-size: 20px;
  color: $primary-light;
  line-height: 36px;
  font-weight: 600;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay1 #center,
.ecoCalendar #gen-img-preview.DisplayImgPreview.overlay2 #center {
  background-color: $primary-dark;
  width: 100%;
  height: 472.8px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview .list-items .col-wrapper {
  height: 60px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview .list-items .badge {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 25.5px;
  border-radius: 32.5px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview .list-items #header {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.18px;
}

.ecoCalendar #gen-img-preview.DisplayImgPreview .list-items .country-event {
  font-size: 18px;
  font-weight: 600;
  line-height: 24.09px;
  width: 340px;
  max-width: 340px;
  max-height: 50px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Urbanist", "Roboto";
}
.ecoCalendar #gen-img-preview.DisplayImgPreview .list-items .flag-container {
  width: 56px;
}

.ecoCalendar #gen-img.DisplayImg.overlay1 {
  height: 1080px;
  max-width: 1080px;
}
.ecoCalendar #gen-img.DisplayImg.overlay2 {
  height: 1920px;
  max-width: 1080px;
}

.ecoCalendar #gen-img.DisplayImg {
  height: 1080px;
  max-width: 1080px;
}

.ecoCalendar #gen-img.DisplayImg #top .top-badge {
  border-radius: 60px;
  padding: 25px 30px 25px 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.05px;
  text-transform: uppercase;
  span {
    font-weight: 500;
    line-height: normal;
  }
}

.ecoCalendar #gen-img.DisplayImg.overlay1 #top {
  width: 100%;
  height: 225px;
  background-color: $primary-dark;
}

.ecoCalendar #gen-img.DisplayImg {
  &:is(.overlay1) .badge-block {
    justify-content: end;
  }
  &:is(.overlay1).text-block {
    justify-content: start;
  }

  &:is(.overlay2) .badge-block {
    margin-bottom: 51px;
    justify-content: center !important;
  }
  &:is(.overlay2) .text-block {
    justify-content: center !important;
  }
}

.ecoCalendar #gen-img.DisplayImg.overlay2 #top {
  width: 100%;
  height: 586px;
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ecoCalendar #gen-img.DisplayImg.overlay1 #top p,
.ecoCalendar #gen-img.DisplayImg.overlay2 #top p {
  line-height: 120px;
  max-width: 937px;
  margin-left: 22.95px;
  font-weight: 700;
  font-size: 65px;
  color: $primary-light;

  &:is(#gen-img.DisplayImg.overlay2 #top p) {
    margin-left: 0;
    margin-bottom: 30px;
    line-height: 144px;
    font-size: 120px;
    max-width: 100%;
  }
}
.ecoCalendar #gen-img.DisplayImg.overlay1 #bottom {
  width: 100%;
  height: 155px;
  background-color: $primary-dark;
  justify-content: space-between;
}
.ecoCalendar #gen-img.DisplayImg.overlay2 #bottom {
  width: 100%;
  height: 478px;
  background-color: $primary-dark;
  justify-content: center;
  flex-direction: column;
}

.ecoCalendar #gen-img.DisplayImg.overlay2 #bottom img {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 650px;
}
.ecoCalendar #gen-img.DisplayImg {
  color: $primary-light;
  line-height: normal;
  font-weight: 600;
  &:is(.overlay1) #bottom p {
    font-size: 30px;
  }
  &:is(.overlay2) #bottom p {
    font-size: 42px;
  }
}

.ecoCalendar #gen-img.DisplayImg.overlay1 #center {
  background-color: $primary-dark;
  width: 100%;
  height: 700px;
  padding-top: 20px;
}

.ecoCalendar #gen-img.DisplayImg.overlay2 #center {
  background-color: $primary-dark;
  width: 100%;
  height: 856px;
}

.ecoCalendar #gen-img.DisplayImg {
  &:is(.overlay1) .list-items #item {
    margin-top: 14px;
  }
  &:is(.overlay2) .list-items #item {
    margin-top: 20px;
  }
}

.ecoCalendar #gen-img.DisplayImg {
  &:is(.overlay1) .list-items .col-wrapper {
    height: 90px;
  }
  &:is(.overlay2) .list-items .col-wrapper {
    height: 110px;
  }
}

.ecoCalendar #gen-img.DisplayImg .list-items .badge {
  padding: 9px 14px;
  font-weight: 700;
  border-radius: 32.5px;
  max-width: 120px;

  &:is(.overlay1) .list-items .badge {
    font-size: 22px;
    line-height: 25.5px;
  }
  &:is(.overlay2) .list-items .badge {
    font-size: 29px;
    line-height: normal;
  }
}

.ecoCalendar #gen-img.DisplayImg .list-items #header {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.18px;
}

.ecoCalendar #gen-img.DisplayImg .list-items .country-event {
  font-size: 30px;
  font-weight: 600;
  line-height: 37.09px;
  width: 580px;
  max-width: 580px;
  max-height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Urbanist", "Roboto";
}

.ecoCalendar #gen-img.DisplayImg .list-items {
  font-size: 24px;
  line-height: normal;
  & .day {
    font-weight: 700;
  }
  & .time {
    font-weight: 400;
  }
}

.ecoCalendar #gen-img.DisplayImg .list-items .flag-container {
  width: 76px;
}

/*************************Financial-Analysis*************************/

.financial-analysis.DisplayImg.overlay1 > h2.small {
  font-size: 110px;
  line-height: 130%;
}
.financial-analysis.DisplayImg.overlay2 > h2.small {
  font-size: 80px;
  line-height: 130%;
}
.financial-analysis.DisplayImg.overlay1 > h2.medium {
  font-size: 120px;
  line-height: 130%;
}
.financial-analysis.DisplayImg.overlay2 > h2.medium {
  font-size: 90px;
  line-height: 130%;
}
.financial-analysis.DisplayImg.overlay1 > h2.large {
  font-size: 130px;
  line-height: 130%;
}
.financial-analysis.DisplayImg.overlay2 > h2.large {
  font-size: 100px;
  line-height: 130%;
}
.financial-analysis#gen-img-preview.overlay1 > h2.small {
  font-size: 2rem;
}
.financial-analysis#gen-img-preview.overlay2 > h2.small {
  font-size: 3.75rem;
}
.financial-analysis#gen-img-preview.overlay1 > h2.medium {
  font-size: 2.5rem;
}
.financial-analysis#gen-img-preview.overlay2 > h2.medium {
  font-size: 4.375rem;
}
.financial-analysis#gen-img-preview.overlay1 > h2.large {
  font-size: 2.75rem;
}
.financial-analysis#gen-img-preview.overlay2 > h2.large {
  font-size: 5rem;
}

.financial-analysis .image-details {
  border-bottom: 0.5px solid #e2e5ec;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

//logo
.financial-analysis#gen-img-preview .gomarkets-logo {
  position: absolute;
  top: 25px;
  left: 35px;
  z-index: 2;
  max-width: 100%;
}
.financial-analysis#gen-img-preview.overlay1 .gomarkets-logo {
  width: 295px;
  height: 39px;
}
.financial-analysis#gen-img-preview.overlay2 .gomarkets-logo {
  width: 221px;
  height: 29px;
}

.financial-analysis.DisplayImg .gomarkets-logo {
  top: 56px;
  left: 56px;
}

.financial-analysis.overlay1 .gomarkets-logo {
  width: 590px;
  height: 78px;
}
.financial-analysis.overlay2 .gomarkets-logo {
  width: 442px;
  height: 58px;
}

.financial-analysis#gen-img-preview.DisplayImgPreview.overlay1 {
  height: 399px;
  width: 100%;
  max-width: 764px !important;
}

.financial-analysis#gen-img-preview.DisplayImgPreview.overlay2 {
  height: 700px;
  width: 100%;
  max-width: 700px;
}

.financial-analysis.overlay1 {
  width: 1920px;
  height: 1080px;
}
.financial-analysis.overlay2 {
  width: 1080px;
  height: 1080px;
}

.financial-analysis .financial-img-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.financial-analysis .imgText {
  position: relative;
  width: 100%;
  margin: auto;
}

.financial-analysis.DisplayImg {
  background: linear-gradient(
    90deg,
    rgba(9, 31, 44, 0.45) 60.43%,
    rgba(9, 31, 44, 0) 100%
  );
}

//image title text
.financial-analysis > h2 {
  position: absolute;
  transform: none;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  color: white;
  font-family: "goma-sans-bold";
  z-index: 2;
}

.financial-analysis#gen-img-preview.overlay1 > h2 {
  top: 80px;
  left: 40px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.financial-analysis#gen-img-preview.overlay2 > h2 {
  top: 80px;
  left: 40px;
  width: 100%;
  max-width: 500px;
  max-height: 380px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.financial-analysis.DisplayImg.overlay1 > h2 {
  top: 165px;
  left: 80px;
  font-weight: 800;
  max-width: 1060px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.financial-analysis.DisplayImg.overlay2 > h2 {
  left: 80px;
  top: 165px;
  max-height: 530px;
  max-width: 930px;
  overflow: hidden;
}

//bottom text container
.financial-analysis .bottomTextContainer {
  position: absolute;
  background: linear-gradient(93deg, #1f9a4d 17.37%, #3aeb7c 106.56%);
  z-index: 1;
  span {
    color: white;
    font-style: normal;
    font-weight: 700;
    line-height: 141.5%;
  }
  &.jackyWang {
    background: linear-gradient(93deg, #1f9a4d 17.37%, #3aeb7c 106.56%);
  }
  &.mikeHuang {
    background: linear-gradient(93deg, #1f9a4d 17.37%, #3aeb7c 106.56%);
  }
  &.yoyoMa {
    background: linear-gradient(93deg, #1f9a4d 17.37%, #3aeb7c 106.56%);
  }
  &.michelleChen {
    background: linear-gradient(90deg, #091f2c 16.5%, #1c82bf 89.5%);
  }
}

.financial-analysis#gen-img-preview.overlay1 .bottomTextContainer {
  width: 90%;
  max-width: 694px;
  height: 99px;
  bottom: 30px;
  border-top-left-radius: 12.4px;
  border-bottom-right-radius: 12.4px;
  span {
    font-size: 24px;
  }
}

.financial-analysis#gen-img-preview.overlay2 .bottomTextContainer {
  width: 90%;
  max-width: 630px;
  height: 162px;
  bottom: 30px;
  border-top-left-radius: 12.4px;
  border-bottom-right-radius: 12.4px;
  span {
    font-size: 23px;
  }
}

.financial-analysis.DisplayImg.overlay1 .bottomTextContainer {
  width: 1780px;
  height: 320px;
  bottom: 70px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  span {
    font-size: 50px;
  }
}
.financial-analysis.DisplayImg.overlay2 .bottomTextContainer {
  width: 968px;
  height: 244px;
  bottom: 56px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  span {
    font-size: 50px;
  }
}

.financial-analysis.overlay2.DisplayImg > p {
  left: 80px;
  bottom: 80px;
}

.financial-analysis .financialCont {
  position: relative;
}

.financial-analysis#gen-img-preview.DisplayImgPreview .financialCont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 31, 44, 0.45) !important;
  z-index: 1;
}

.financial-analysis .financialCont #right {
  float: right;
}

.financial-analysis#gen-img-preview.overlay1 .financialCont #right {
  width: 208.99px;
  height: 369.13px;
  margin-right: 20px;
  margin-top: 0;
}

.financial-analysis#gen-img-preview.overlay2 .financialCont #right {
  width: 280px;
  height: 350px;
  margin-right: -20px;
  margin-top: 320px;
}
.financial-analysis#gen-img-preview.overlay2 .financialCont #right img {
  height: 100%;
}

.financial-analysis.DisplayImg.overlay1 .financialCont #right {
  width: 560px;
  height: 820px;
  margin-right: 70px;
  margin-top: 190px;
}
.financial-analysis#gen-img.overlay1 .financialCont #right img {
  height: 820px;
  width: 100%;
}
.financial-analysis.DisplayImg.overlay2 .financialCont #right {
  width: 360px;
  height: 514px;
  margin-right: 55px;
  margin-top: 510px;
}
.financial-analysis#gen-img.overlay2 .financialCont #right img {
  height: 540px;
  width: 100%;
}

.financial-analysis.DisplayImg .financialCont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 31, 44, 0.45) !important;
  z-index: 1;
}

//badge
.financial-analysis#gen-img-preview .badge {
  position: absolute;
  background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 11.5%;
  padding: 4.777px 8.164px 4.742px 7.961px;
  z-index: 2;
  font-family: Noto Sans SC;
  &.jackyWang {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.mikeHuang {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.yoyoMa {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.michelleChen {
    background: #1d80bb;
  }
}

.financial-analysis#gen-img-preview.overlay1 .badge {
  width: 154px;
  height: 32px;
  top: 25px;
  right: 35px;
  font-size: 18px;
  line-height: 22.2px;
  border-radius: 3.46px;
}
.financial-analysis#gen-img-preview.overlay2 .badge {
  width: 131px;
  height: 31px;
  top: 25px;
  right: 35px;
  font-size: 16.8px;
  line-height: 18.65px;
  border-radius: 2.77px;
}

.financial-analysis .badge {
  position: absolute;
  background: linear-gradient(92deg, #0bab47 32.19%, #39f17e 119.78%);
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.3px;
  z-index: 2;
  border-radius: 3.462px;
  line-height: 22.2px;
  &.jackyWang {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.mikeHuang {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.yoyoMa {
    background: linear-gradient(92deg, #0bab47 43.21%, #39f17e 119.78%);
  }
  &.michelleChen {
    background: #1d80bb;
  }
}

.financial-analysis.overlay1 .badge {
  top: 70px;
  right: 70px;
  width: 328px;
  height: 78px;
  font-size: 42px;
}

.financial-analysis.overlay2 .badge {
  top: 56px;
  right: 56px;
  width: 262px;
  height: 62px;
  font-size: 32px;
}

.email .image-settings {
  border-bottom: 0.5px solid #e2e5ec;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.email #gen-img.DisplayImg .img-thumbnail {
  height: 400px;
  width: 650px;
  max-width: 650px;
  background: #091f2c;
  display: block;
  float: none;
  margin: 0 auto;
}

.email #gen-img-wrapper {
  background: #f7f8fa;
  width: 100%;
  border: 1px solid #ecf0fc;
  display: inline-block;
}

.email .DisplayImg {
  float: none;
}

.email .DisplayImg > h2.small {
  font-size: 42px;
}
.email .DisplayImg > h2.medium {
  font-size: 48px;
}
.email .DisplayImg > h2.large {
  font-size: 54px;
}

.email .DisplayImg > h2 {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  height: max-content;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 48px;
  color: white;
  font-family: goma-sans-semi;
  font-weight: 800;
  line-height: 52px;
  max-width: 100%;
  width: 100%;
  z-index: 2;
  text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
}

.email button.btn-primary {
  font-size: 1rem;
}

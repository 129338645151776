@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700;800&family=Noto+Sans+SC:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700&family=Sarabun:wght@400;500;600;700&family=Inter:wght@400;500;600;700;800&family=Urbanist:ital,wght@0,100..900;1,100..900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*------Color Pallet------*/
$theme: #0bab47;
$primary-light: white;
$secondary-light: #e0e4ee;
$primary-dark: #091f2c;
$secondary-dark: #0c1018;
$theme-orange: #ff5500;
$tag-color: rgb(244 114 182);
$cursor: rgb(187, 82, 178);

/*------Font------*/
$size-sm: 0.75rem;
$size-md: 0.875rem;
$size-lg: 1.2rem;

$font-header: "Poppins", "Roboto", "sans-serif", Arial, Helvetica;
$font-body: "Roboto", sans-serif, Arial, Helvetica;

/*------Mixins------*/
@mixin font($fntFam, $fntSize, $fntW, $lineH, $ltrSpacing) {
  font-family: $fntFam;
  font-size: $fntSize;
  font-weight: $fntW;
  line-height: $lineH;
  letter-spacing: $ltrSpacing;
}

@font-face {
  font-family: "goma-sans-semi";
  src: local("goma-sans-semi"),
    url(../font/GOMA_SEMIBOLD.otf) format("opentype");
}

@font-face {
  font-family: "goma-sans-bold";
  src: local("goma-sans-bold"), url(../font/GOMA_BOLD.otf) format("opentype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
a,
h5,
h6 {
  font-family: $font-body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:any-link:hover:not(#options, #navLinks) {
  color: $theme !important;
  transition: 0.2s all;
}
:focus-visible {
  outline: none;
}

::selection {
  background: $theme;
  color: $primary-light;
}
::-moz-selection {
  background: $theme;
  color: $primary-light;
}
.theme-variant button {
  background: $theme 0% 0% no-repeat padding-box !important;
  font-weight: bold;
  border: none !important;
  border-radius: 3px;
  font: normal normal bold 18px/20px;
  padding: 6px, 12px, 6px, 12px;
  &:hover {
    background: #00893e !important;
  }
}
.link-variant :is(a, button) {
  background-color: transparent !important;
  color: $primary-dark !important;
  border: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  padding: 13.58px 14px 12.42px 12px;
  &:hover {
    background: #e7f7ed !important;
    color: $theme !important;
  }
}

.show-more {
  background: #e7f7ed !important;
  color: $theme !important;
  border: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  padding: 12.42px 12px 12.42px 12px;
}

#mainNav {
  background-color: transparent;
}

@media only screen and (max-width: 640px) {
  .link-variant a {
    background-color: transparent !important;
    color: $theme !important;
    border: none !important;
    font-weight: 600 !important;
    border-radius: 4px;
    padding: 13.58px 14px 12.42px 12px;
  }
}

button span {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
}

a span[data-testid="flowbite-badge"] {
  padding: 10px;

  &:hover {
    background-color: rgb(187 247 208);
  }
}
